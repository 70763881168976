<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0" style="font-size: 25px; font-weight: 550">
                    {{ this.$route.params.name_en }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                  <v-dialog v-model="dialog" max-width="500px" persistent>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="float-right"
                        style="background-color: #5de99e"
                      >
                        <span style="font-size: 1em; color: #000000">
                          <i class="fas fa-user-plus">
                            <label class="ml-2 btn_create_new">{{
                              $t("generate_annual_score")
                            }}</label>
                          </i>
                        </span>
                      </v-btn>
                    </template>

                    <v-card color="teal accent-3">
                      <v-card-title>{{
                        $t("generate_annual_score")
                      }}</v-card-title>

                      <v-icon
                        class="btn_close"
                        @click="
                          dialog = false;
                          clear();
                        "
                        >close</v-icon
                      >
                      <v-divider />
                      <v-card-text
                        style="background-color: #edf1f5; color: #333"
                      >
                        <v-container>
                          <v-row>
                            <v-card-title color="white">{{
                              $t("select_session")
                            }}</v-card-title>

                            <v-divider />

                            <v-data-table
                              :headers="headers"
                              :items="listSession"
                              :items-per-page="25"
                              :footer-props="{
                                'items-per-page-options': [10, 25, 50, -1],
                              }"
                              style="max-height: 400px; overflow-y: auto"
                              item-key="id"
                              hide-default-header
                              class="elevation-1"
                            >
                              <template v-slot:header="{}">
                                <thead>
                                  <tr>
                                    <th>
                                      <label class="form-checkbox">
                                        <input
                                          type="checkbox"
                                          v-model="selectAll"
                                          @click="select"
                                        />
                                        <i class="form-icon"></i>
                                      </label>
                                    </th>
                                    <th>{{ $t("select_all_session") }}</th>
                                  </tr>
                                </thead>
                              </template>
                              <template v-slot:body="{ items }">
                                <tbody
                                  style="white-space: nowrap"
                                  v-if="items.length > 0"
                                >
                                  <tr
                                    v-for="(sess, index) in items"
                                    v-bind:key="index"
                                  >
                                    <td>
                                      <label class="form-checkbox">
                                        <input
                                          type="checkbox"
                                          :value="sess.id"
                                          v-model="selected"
                                          :disabled="sess.score_approval !== 1"
                                        />
                                        <i class="form-icon"></i>
                                      </label>
                                    </td>

                                    <td>
                                      {{ sess.name_en }}
                                      <v-chip
                                        class="float-right text-right"
                                        color="green"
                                        outlined
                                        small
                                        v-show="sess.score_approval == 1"
                                        >Approved</v-chip
                                      >
                                      <v-chip
                                        class="float-right text-right"
                                        color="blue"
                                        outlined
                                        small
                                        v-show="sess.score_approval == 2"
                                        >Already Generated</v-chip
                                      >
                                      <v-chip
                                        class="float-right text-right"
                                        color="red"
                                        small
                                        outlined
                                        v-show="sess.score_approval == 0"
                                        >Not Yet Approved</v-chip
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td
                                      :colspan="headers.length + 1"
                                      style="text-align: center"
                                    >
                                      <v-alert dense outlined type="error">
                                        There is currently no session available.
                                      </v-alert>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-data-table>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions class="function_footer">
                        <v-btn
                          class="btn_cancel float-left"
                          @click="(dialog = false), clear()"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-btn
                          class="btn_save_close float-right"
                          @click.prevent="onSaveClose()"
                          >{{ $t("save_close") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>

              <div style="margin-top: 20px"></div>
              <v-breadcrumbs :items="items" :href="items.href">
                <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>

              <v-row v-if="listAnnual.length > 0">
                <v-col sm="4" cols="12" v-for="(ann, i) in listAnnual" :key="i">
                  <v-card
                    color="#fccece"
                    dark
                    max-width="500"
                    class="mx-auto"
                    @click="
                      $router.push({
                        name: 'GenerateAnnual',
                        params: {
                          session_ids: JSON.stringify(ann.session_ids),
                          annual_id: ann.annual_id,
                        },
                      })
                    "
                  >
                    <v-img
                      src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fbg4.jpg?alt=media&token=b694d08d-af07-4484-aa6f-b5ccc3ffff7e"
                      width="500"
                      height="150"
                    >
                      <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            style="float: right"
                            v-on="on"
                          >
                            <v-icon color="#ffff">mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item link @click="deleteannual(ann)">
                            <v-list-item-title>Delete</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <div align="center" justify="center" class="mt-8">
                        <div class="text-h5 text-center">Annual Score</div>
                        <div class="text-h5 text-center">For</div>

                        <div class="text-h5 text-center">{{ ann.name_en }}</div>
                      </div>
                      <router-link
                        style="text-decoration: none"
                        :to="
                          'tsc/manage/annual-score/generation/annual/score/' +
                          JSON.stringify(ann.session_ids) +
                          '&' +
                          ann.annual_id
                        "
                        tag="button"
                      >
                      </router-link>
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else align="center" justify="center" class="mb-5">
                <div>
                  <v-img
                    src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fempty.png?alt=media&token=15a9dbf8-dca3-4df9-981d-f621d354e4ae"
                    class="center"
                  ></v-img>
                  <div class="text-h6 text-center">
                    There is no data for you to see yet
                  </div>
                  <div class="text-h6 text-center">
                    If you want to genrate new annual , just click
                  </div>
                  <div class="text-h6 text-center">
                    <v-btn disabled style="background-color: #5de99e">
                      <span style="font-size: 1em; color: #000000">
                        <i class="fas fa-user-plus">
                          <label class="ml-2 btn_create_new">{{
                            $t("generate_annual_score")
                          }}</label>
                        </i>
                      </span>
                    </v-btn>
                    button and go ahead.
                  </div>
                </div>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>

<script>
import {
  getSessionByMajor,
  getAnnual,
  storeAnnualScore,
  deleteAnnual,
} from "@schoolbase/web-client-lib";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "@/store";

export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data: () => ({
    listSession: [],
    token: store.getters.getToken,

    isLoading: false,
    snackbar: {
      show: false,
      message: null,
      color: null,
    },
    selected: [],
    listAnnual: [],
    selectAll: false,
    headers: [{ text: "Term", value: "term" }],
    items: [
      {
        text: "Major",
        disabled: false,
        href: "javascript:history.go(-1)",
      },
      {
        text: "Generation",
        disabled: false,
        href: "javascript:history.go(-1)",
      },
      {
        text: "Annual Score",
        disabled: true,
      },
    ],
    dialog: false,
    myLoading: false,
  }),
  computed: {},
  methods: {
    clear() {
      this.id = "";
      this.selected = [];
      this.selectAll = "";
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.listSession) {
          if (this.listSession[i].score_approval == 1) {
            this.selected.push(this.listSession[i].id);
          }
        }
      }
    },
    async onSaveClose() {
      try {
        await storeAnnualScore(this.selected);
        this.loadAnnual();
        this.loadSessions();
        this.clear();
        this.dialog = false;
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async loadSessions() {
      try {
        const APIResponse = await getSessionByMajor(
          this.$route.params.major_id,
          this.$route.params.id,
          this.token
        );

        this.listSession = APIResponse.payload;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }
      } catch (e) {
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async loadAnnual() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await getAnnual(
          this.$route.params.major_id,
          this.$route.params.id,
          this.token
        );

        this.listAnnual = APIResponse.payload;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async deleteannual(ann) {
      const index = this.listAnnual.indexOf(ann);
      this.deletItems = ann;
      const btnCancel = confirm("Are you sure you want to delete this item?");
      if (btnCancel === true) {
        this.listAnnual.splice(index, 1);
        await this.deleteAnnual();
      }
    },
    async deleteAnnual() {
      try {
        await deleteAnnual(this.deletItems.annual_id);
        this.loadSessions();
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },
  components: {
    Loading,
  },
  mounted() {
    this.loadSessions();
    this.loadAnnual();
  },
};
</script>
<style scoped>
@media (max-width: 576px) {
}
.btn_create_new {
  color: #000000 !important;
  text-transform: capitalize !important;
  height: 40px !important;
  border: none !important;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 14px;
}
</style>
